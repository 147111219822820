import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Page from 'components/Page';
import Input from 'components/Input';
import Button from 'components/Button';

import * as MeActions from 'store/modules/me/actions';
import api from 'services/api';
import { getToken, setToken } from 'services/auth';

import logoClube from 'assets/images/logo-clube.svg';
import './login.css';

export default function Login() {
  const token = getToken();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({
    username: '',
    password: ''
  });

  function fetchMe() {
    return api.get('/v1/adm/me')
      .then(({ data: me }) => {
        dispatch(MeActions.me(me));
      })
      .catch((error) => {
        alert(error?.response?.data?.message || 'Não foi possivel buscar suas informações!');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleInput(event) {
    const { name, value } = event.target;

    setLogin({
      ...login,
      [name]: value
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    const body = {
      method: 'password',
      ...login
    }

    api.post(`/v1/login`, body)
      .then(({ data }) => {
        const { token } = data;

        setToken(token);
        fetchMe();
      })
      .catch((error) => {
        setLoading(false);
        alert(error?.response?.data?.message || 'Não foi possivel efetuar o login!');
      });
  }

  if (token) {
    return <Redirect to="/meus-dados" />
  }

  return (
    <Page>
      <div className="container">
        <div className="login">
          <div className="login-info">
            <div className="login-icon">
              <img src={logoClube} alt="" />
            </div>
            <h1 className="title">Unicred Clube de Vantagens</h1>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="login-input">
              <Input
                label="Login"
                name="username"
                onChange={handleInput}
                value={login.username}
                placeholder="Insira seu login"
                border={false}
                required
              />
            </div>

            <div className="login-input">
              <Input
                label="Senha"
                name="password"
                type="password"
                onChange={handleInput}
                value={login.password}
                placeholder="Insira sua senha"
                border={false}
                required
              />
            </div>

            <div className="login-actions">
              <Button loading={loading}>Entrar</Button>
              <Link to="/redefinir-senha">Esqueceu sua senha?</Link>
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
}
