import Page from "components/Page";
import Footer from "components/Footer";
import { useEffect, useState } from "react";

import api from "services/api";

import "./partner-list.css";

export default function Home() {
  const [partnersLogos, setPartnersLogos] = useState([]);

  useEffect(() => {
    fetchPartnersLogos();
  }, []);

  function fetchPartnersLogos() {
    api
      .get(`/v1/partners/logos`)
      .then(({ data: result }) => {
        setPartnersLogos(result);
        console.log(result);
      })
      .catch(() => {})
      .finally(() => {});
  }

  return (
    <Page>
      <div className="partner-list-page">
        <div className="container">
          <div className="partner-list-page-header">
            <h1 className="title">Parceiros Clube de Vantagens</h1>
            <p>
              Confira abaixo os parceiros que participam do Clube de Vantagens
              Unicred:
            </p>
          </div>
          <ul className="partner-list">
            {partnersLogos.map((logo) => {
              return (
                <li>
                  <div className="partner-list-image">
                    <img src={logo.link} alt="Partner logo" />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <Footer />
    </Page>
  );
}
