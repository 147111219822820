import { useLocation } from 'react-router-dom';
import './nav.css';

export default function Nav() {
  const location = useLocation();

  const menu = [
    { href: '/', title: 'Início' },
    { href: '/parceiros', title: 'Parceiros' },
    { href: 'https://hallo-static.s3.sa-east-1.amazonaws.com/unicred-clube-saiba-mais.html.pdf', title: 'Saiba mais', target: '_blank' }
  ];

  return (
    <nav className="nav">
      <ul>
        {menu.map((menu, i) => (
          <li key={i}>
            <a
              href={menu.href}
              className={location.pathname === menu.href ? ' active' : ''}
              target={menu.target}
            >
              <span>{menu.title}</span>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
