import { Link } from 'react-router-dom';
import './button-link.css';

export default function ButtonLink({ children, className, ...props }) {
  return (
    <Link className={`button-link${className ? ` ${className}` : ''}`} {...props}>
      {children}
    </Link>
  );
}
