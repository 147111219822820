import Loading from 'components/Loading';
import './button.css';

export default function Button({ children, className, outline, disabled, loading, ...props }) {
  return (
    <button
      className={`button${className ? ` ${className}` : ''}${outline ? ' outline' : ''}`}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <Loading size="small" /> : children}
    </button>
  );
}
