import Page from 'components/Page';
import ButtonLink from 'components/ButtonLink';
import Footer from 'components/Footer';

import banner from 'assets/images/banner.png';
import vantagens1 from 'assets/images/vantagens-1.svg';
import vantagens2 from 'assets/images/vantagens-2.svg';
import vantagens3 from 'assets/images/vantagens-3.svg';
import vantagens4 from 'assets/images/vantagens-4.svg';
import vantagens5 from 'assets/images/vantagens-5.svg';
import './home.css';

export default function Home() {
  return (
    <Page>
      <section className="section-main">
        <img src={banner} alt="" />
      </section>

      <section className="section-clube">
        <div className="container">
          <div className="section-clube-header">
            <span>O que é e como funciona o Clube</span>
          </div>

          <div className="section-clube-columns">
            <div className="section-clube-column-left">
              Um Portal de Benefícios e Classificados exclusivo para o Cooperado Unicred. São descontos em estabelecimentos conveniados em todo país. <strong>Venha fazer parte desta rede!</strong>
            </div>

            <div className="section-clube-column-right">
              Os parceiros cadastram suas empresas com descontos diferenciados ao seleto grupo de cooperados da Unicred. Por isso, o clube busca parcerias com empresas renomadas que façam parte do estilo de vida e hábitos de consumo desse público.
            </div>
          </div>
        </div>
      </section>

      <section className="section-partner">
        <div className="container">
          <div className="section-partner-info">
            <h1>Vantagens de se tornar um parceiro</h1>
            <p>Ao se tornar um parceiro do Clube de Vantagens Unicred, você ganha a visibilidade de um grupo de mais de 200 mil cooperados, o que proporcionará o fomento de novos negócios e fidelização de clientes atuais.</p>
          </div>

          <ul className="benefits">
            <li>
              <div className="benefits-image">
                <img src={vantagens1} alt="Acesso à cooperados de alto nível" />
              </div>
              <span>Acesso à cooperados de alto nível;</span>
            </li>
            <li>
              <div className="benefits-image">
                <img src={vantagens2} alt="Captação de novos clientes" />
              </div>
              <span>Captação de novos clientes;</span>
            </li>
            <li>
              <div className="benefits-image">
                <img src={vantagens3} alt="Fidelização de atuais clientes" />
              </div>
              <span>Fidelização de atuais clientes;</span>
            </li>
            <li>
              <div className="benefits-image">
                <img src={vantagens4} alt="Facilitação de crédito a cooperados interessados em participar do Clube" />
              </div>
              <span>Facilitação de crédito a cooperados interessados em participar do Clube;</span>
            </li>
            <li>
              <div className="benefits-image">
                <img src={vantagens5} alt="Sua empresa será divulgada para mais de 200 mil cooperados" />
              </div>
              <span>Sua empresa será divulgada para mais de 200 mil cooperados.</span>
            </li>
          </ul>

          <div className="section-partner-action">
            <ButtonLink to="/seja-um-parceiro">Seja um parceiro</ButtonLink>
          </div>
        </div>
      </section>

      <Footer />
    </Page>
  );
}
