import { Link } from 'react-router-dom';
import './footer.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <ul className="footer-links">
          <li>
            <Link to="/sobre">Sobre</Link>
          </li>
          <li>
            <Link to="/ajuda">Ajuda</Link>
          </li>
          <li>
            <Link to="/politica-de-privacidade">Política de privacidade</Link>
          </li>
        </ul>

        <div className="footer-copyright">
          &copy; 2020 Unicred
        </div>
      </div>
    </footer>
  );
}
