import { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Input from 'components/Input';
import Select from 'components/Select';
import Radio from 'components/Radio';
import Button from 'components/Button';
import Loading, { Center } from 'components/Loading';
import toast from 'react-hot-toast';

import api from 'services/api';
import './address-form.css';

export default function Form({
  selectedAddress,
  setSelectedAddress,
  filter,
  fetchAddresses,
  setFilter,
  onRequestClose
}) {
  const defaultAddress = {
    title: '',
    street: '',
    lat: '',
    lng: '',
    number: '',
    neighborhood: '',
    zipcode: '',
    city_id: '',
    link: '',
    contact_by_email: '',
    contact_by_phone: '',
    contact_by_whatsapp: ''
  };
  const types = [
    { value: 'physical', label: 'Estabelecimento físico' },
    { value: 'online', label: 'Estabelecimento online' }
  ];
  const defaultType = filter;
  const [type, setType] = useState(defaultType);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [address, setAddress] = useState(defaultAddress);

  useEffect(() => {
    if (selectedAddress) {
      setType(selectedAddress.type);
      setAddress({
        title: selectedAddress.title,
        street: selectedAddress.street,
        lat: selectedAddress.lat,
        lng: selectedAddress.lng,
        number: selectedAddress.number,
        neighborhood: selectedAddress.neighborhood,
        zipcode: selectedAddress.zipcode,
        city_id: selectedAddress.city?.id,
        link: selectedAddress.link || '',
        contact_by_email: selectedAddress.contact_by_email || '',
        contact_by_phone: selectedAddress.contact_by_phone || '',
        contact_by_whatsapp: selectedAddress.contact_by_whatsapp || ''
      });
    }

    return () => {
      setSelectedAddress(null);
    }
  }, [selectedAddress, setSelectedAddress]);

  useEffect(() => {
    api.get('/v1/cities')
      .then(({ data: cities }) => {
        setCities(cities);
      })
      .catch((error) => {
        alert(error?.response?.data?.message || 'Não foi possivel buscar as cidades!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!document.getElementById('script-google-maps')) {
      window.initMap = initMap;
      const script = document.createElement('script');
      script.id = 'script-google-maps';
      script.defer = true;
      script.async = true;
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCgf3u31GeXbpR8_cy_Xj8LFiGMnDEKz-4&libraries=places&callback=initMap';
      document.body.appendChild(script);
    }
  }, []);

  function initMap() {
    window.initFormPatern && window.initFormPatern();
  }

  function handleChangePlacesAutocomplete(fullAddress) {
    setAddress({
      ...address,
      street: fullAddress
    });
  }

  function handleSelectPlacesAutocomplete(place) {
    geocodeByAddress(place)
      .then(async (results) => {
        const [firstResult] = results;
        const addressComponents = firstResult ? firstResult.address_components : [];

        let street = addressComponents.find((addressComponent) => {
          const types = addressComponent.types;
          return types.includes('route') || types.includes('sublocality') || types.includes('sublocality_level_1');
        });

        if (street) {
          street = street.long_name;
        } else {
          street = place ? place.split(' - ')[0] : '';
        }

        const { lat, lng } = await getLatLng(firstResult);

        setAddress({
          ...address,
          street,
          lat,
          lng
        });
      })
      .catch(() => {
        setAddress({
          ...address,
          street: '',
          lat: '',
          lng: ''
        });
      });
  };

  function handleRadio(event) {
    const { value } = event.target;

    setAddress({
      ...defaultAddress,
      title: address.title
    });
    setType(value);
  }

  function handleInput(event) {
    const { name, value } = event.target;

    setAddress({
      ...address,
      [name]: value
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmitting(true);

    try {
      const body = {
        ...address,
        type
      };

      if (selectedAddress) {
        await api.put(`/v1/addresses/${selectedAddress.guid}`, body);
      } else {
        await api.post('/v1/addresses', body);
      }
      toast.success('Salvo com sucesso');
    } catch (error) {
      setSubmitting(false);
      toast.error(error?.response?.data?.message || `Não foi possivel ${selectedAddress ? 'atualizar' : 'criar'} o endereço!`);

      return;
    }

    setSubmitting(false);

    if (filter === type) {
      fetchAddresses();
    } else {
      setFilter(type);
    }

    onRequestClose();
  }

  return (
    <div className="address-form-container">
      <div className="address-form-header">
        {selectedAddress ? 'Editar' : 'Adicionar novo'} endereço
      </div>

      {loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <form className="address-form-body" onSubmit={handleSubmit}>
          <div className="address-form-input">
            <ul className="address-form-radio-group">
              {types.map(({ value, label }) => (
                <li key={value}>
                  <Radio
                    label={label}
                    id={`type-${value}`}
                    name="type"
                    onChange={handleRadio}
                    value={value}
                    checked={value === type}
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className="address-form-input">
            <Input
              label="Título"
              name="title"
              onChange={handleInput}
              value={address.title}
              placeholder="Insira o nome da sua loja"
              required
            />
          </div>

          {type === 'physical' ? (
            <>
              <div className="address-form-input address-form-columns">
                <div className="address-form-column-left">
                  <PlacesAutocomplete
                    value={address.street}
                    onChange={handleChangePlacesAutocomplete}
                    onSelect={handleSelectPlacesAutocomplete}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                      <div className="places-autocomplete">
                        <Input
                          label="Logradouro"
                          {...getInputProps({
                            placeholder: 'Insira o logradouro',
                            required: true
                          })}
                        />
                        <div className="places-autocomplete-container">
                          <ul className="places-autocomplete-list">
                            {suggestions.map((suggestion, i) => (
                              <li key={i} {...getSuggestionItemProps(suggestion)}>
                                {suggestion.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>

                <div className="address-form-column-right">
                  <Input
                    label="Número"
                    name="number"
                    type="number"
                    onChange={handleInput}
                    value={address.number}
                    placeholder="Insira o número"
                    required
                  />
                </div>
              </div>

              <div className="address-form-input address-form-columns">
                <div className="address-form-column-left">
                  <Input
                    label="Bairro"
                    name="neighborhood"
                    onChange={handleInput}
                    value={address.neighborhood}
                    placeholder="Insira o bairro"
                    required
                  />
                </div>

                <div className="address-form-column-right">
                  <Input
                    label="CEP"
                    name="zipcode"
                    onChange={handleInput}
                    value={address.zipcode}
                    placeholder="Insira o CEP"
                    mask="99999-999"
                    required
                  />
                </div>
              </div>

              <div className="address-form-input">
                <Select
                  label="Cidade"
                  name="city_id"
                  onChange={handleInput}
                  value={address.city_id}
                >
                  <option value="">Insira a cidade</option>
                  {cities.map(({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </Select>
              </div>
            </>
          ) : (
            <div className="address-form-input">
              <Input
                label="Link"
                name="link"
                type="url"
                onChange={handleInput}
                value={address.link}
                placeholder="Insira o link da sua loja"
                required
              />
            </div>
          )}

          <div className="address-form-input">
            <Input
              label="E-mail"
              name="contact_by_email"
              type="email"
              onChange={handleInput}
              value={address.contact_by_email}
              placeholder="Insira seu e-mail"
            />
          </div>

          <div className="address-form-columns">
            <div className="address-form-column-50">
              <Input
                label="Telefone"
                name="contact_by_phone"
                onChange={handleInput}
                value={address.contact_by_phone}
                placeholder="Insira seu telefone"
              />
            </div>

            <div className="address-form-column-50">
              <Input
                label="WhatsApp"
                name="contact_by_whatsapp"
                onChange={handleInput}
                value={address.contact_by_whatsapp}
                placeholder="Insira seu whatsapp"
              />
            </div>
          </div>

          <div className="address-form-actions">
            <Button type="button" onClick={onRequestClose} disabled={submitting} outline>Cancelar</Button>
            <Button type="submit" loading={submitting}>Salvar</Button>
          </div>
        </form>
      )}
    </div>
  );
}
