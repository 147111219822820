import { useRef } from 'react';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import './input.css';

export default function Input({ type, label, border = true, mask, currency, ...props }) {
  const textInput = useRef(null);

  function handleClick() {
    textInput.current.focus();
  }

  return (
    <div className={`input ${border ? ' border' : ''}`}>
      {label && <label onClick={handleClick}>{label}</label>}
      {currency ? (
        <CurrencyInput
          {...props}
          decimalSeparator=","
          thousandSeparator="."
          prefix="R$ "
          ref={textInput}
        />
      ) : mask ? (
        <InputMask
          {...props}
          type={type || 'text'}
          mask={mask}
          ref={textInput}
        />
      ) : (
        <input
          {...props}
          type={type || 'text'}
          ref={textInput}
        />
      )}
    </div>
  );
}
