import './loading.css';

export function Center({ children }) {
  return (
    <div className="loading-center">
      {children}
    </div>
  );
}

export default function Loading({ size }) {
  return (
    <div className={`loading-donut${size ? ` ${size}` : ''}`} />
  );
}
