import { useState } from 'react';
import { useHistory, Link, Redirect } from 'react-router-dom';
import Page from 'components/Page';
import Input from 'components/Input';
import Button from 'components/Button';

import api from 'services/api';
import { useQuery } from 'hooks/use-query';

import password from 'assets/images/password.svg';

export default function ChangePassword() {
  const history = useHistory();
  const query = useQuery();
  const token = query.get('token');

  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    password: '',
    confirm_password: ''
  });

  function handleContinue() {
    history.push('/login');
  }

  function handleInput(event) {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitting(true);

    if (form.password !== form.confirm_password) {
      alert('As senhas são diferentes. Tente novamente.');
      setSubmitting(false);
      return;
    }

    const body = {
      ...form,
      token
    };

    api.post(`/v1/change-password`, body)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          setError(true);
        } else {
          alert(error?.response?.data?.message || 'Não foi possivel redefinir sua senha, tente novamente!');
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  if (!token) {
    return <Redirect to="/redefinir-senha" />
  }

  return (
    <Page>
      <div className="container">
        <div className="reset">
          {success ? (
            <div className="reset-info">
              <h1 className="title">Você alterou sua senha</h1>
              <Button onClick={handleContinue}>Fazer login</Button>
            </div>
          ) : error ? (
            <div className="reset-info">
              <p>
                <strong>O link de redefinição de senha expirou ou é inválido.</strong><br />
                <Link className="link" to="/redefinir-senha">Tente redefinir sua senha novamente.</Link>
              </p>
            </div>
          ) : (
            <>
              <div className="reset-info">
                <img src={password} alt="" />
                <h1 className="title">Redefinição de senha</h1>
                <p>Para recuperar o acesso à sua conta, escolha uma nova senha:</p>
              </div>

              <form className="reset-form" onSubmit={handleSubmit}>
                <div className="reset-input">
                  <Input
                    label="Nova senha"
                    name="password"
                    type="password"
                    onChange={handleInput}
                    value={form.password}
                    required
                  />
                </div>

                <div className="reset-input">
                  <Input
                    label="Repita a senha"
                    name="confirm_password"
                    type="password"
                    onChange={handleInput}
                    value={form.confirm_password}
                    required
                  />
                </div>

                <div className="reset-actions">
                  <Button loading={submitting}>Continuar</Button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </Page>
  );
}
