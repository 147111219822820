import ReactModal from 'react-modal';
import IconClose from 'assets/images/icon-close.png';
import './modal.css';

export default function Modal({
  children,
  className = 'modal-container',
  modalOverlay = 'modal-overlay',
  shouldCloseOnOverlayClick = false,
  shouldCloseOnEsc = false,
  ariaHideApp = false,
  onRequestClose,
  showCloseButton = true,
  ...props
}) {
  return (
    <ReactModal
      className={className}
      overlayClassName={modalOverlay}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={false}
      ariaHideApp={ariaHideApp}
      onRequestClose={onRequestClose}
      {...props}
    >
      {showCloseButton && (
        <div className="modal-close" title="Fechar" onClick={onRequestClose}>
          <img src={IconClose} alt="Fechar" />
        </div>
      )}
      {children}
    </ReactModal>
  );
}