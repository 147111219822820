import Nav from './Nav';
import './adm-page.css';

export default function AdmPage({ children }) {
  return (
    <div className="adm-page">
      <div className="container">
        <div className="adm-page-header">
          <h1 className="title">Seja bem vindo, parceiro!</h1>
          <Nav />
        </div>

        <div className="adm-page-body">
          {children}
        </div>
      </div>
    </div>
  );
}
