import { useState } from 'react';
import './select-multiple.css';

export default function SelectMultiple({
  label,
  placeholder,
  items = [],
  setItems,
  data = [],
  noItemsMessage
}) {
  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(!open);
  }

  function handleItem(item) {
    const index = items.findIndex((i) => i.value === item.value);

    if (index !== -1) {
      let newItems = [...items];
      newItems.splice(index, 1);

      setItems(newItems);
    } else {
      setItems([...items, item]);
    }
  }

  function formatValues(values) {
    if (values.length > 2) {
      return `${values.length} selecionados`;
    }
    const text = values.map(({ label }) => label).join(', ');
    return text.length >= 50 ? `${text.substr(0, 50)}...` : text;
  }

  return (
    <div className="select-multiple">
      <div className="select-multiple-info" onClick={handleOpen}>
        {label && <span>{label}</span>}
        {items.length > 0 ? (
          <p className="active">{formatValues(items)}</p>
        ) : placeholder ? (
          <p>{placeholder}</p>
        ) : null}
      </div>

      {open && (
        data.length === 0 ? (
          <div className="select-multiple-message">
            {noItemsMessage || 'Nenhuma opção!'}
          </div>
        ) : (
          <ul className="select-multiple-items">
            {data.map((item) => (
              <li
                key={item.value}
                className={items.find((i) => i.value === item.value) ? 'active' : ''}
                onClick={() => handleItem(item)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        )
      )}
    </div>
  );
}
