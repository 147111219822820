import api from 'services/api';

function setToken(token) {
  api.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };

  window.localStorage.setItem('unicred-clube-token', token);
}

function getToken() {
  return window.localStorage.getItem('unicred-clube-token');
}

function removeToken() {
  window.localStorage.removeItem('unicred-clube-token');
}

export {
  setToken,
  getToken,
  removeToken
};
