import { useState } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';

import './confirmation-modal.css';

export default function ConfirmationModal({
  title,
  description,
  modalIsOpen,
  setModalIsOpen,
  submit,
  onSuccess,
  onError,
  onRequestClose
}) {
  const [submitting, setSubmitting] = useState(false);

  function close() {
    if (onRequestClose) {
      onRequestClose();
    }

    setModalIsOpen(false);
  }

  function handleSumit() {
    setSubmitting(true);

    submit()
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        close();
      })
      .catch((error) => {
        if (onError) {
          onError();
        } else {
          alert(error?.response?.data?.message || `Não foi possivel excluir o item!`);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={close}
      showCloseButton={false}
      className="modal-container-small"
    >
      <div className="confirmation-modal">
        <div className="confirmation-modal-header">
          {title || 'Excluir item'}
        </div>

        <div className="confirmation-modal-body">
          {description || 'Tem certeza que deseja excluir o item?'}
        </div>

        <div className="confirmation-modal-actions">
          <Button type="button" onClick={close} disabled={submitting} outline>Cancelar</Button>
          <Button type="button" onClick={handleSumit} loading={submitting}>Confirmar</Button>
        </div>
      </div>
    </Modal>
  );
}
