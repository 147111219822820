import { useEffect } from 'react';
import Header from 'components/Header';

export default function Page({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {children}
    </>
  );
}
