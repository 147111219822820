import { Provider } from 'react-redux';
import { store } from './store';
import Routes from './routes';
import { Toaster } from 'react-hot-toast';
import toastConfig from 'config/toast'
export default function App() {
  return (
    <Provider store={store}>
      <Routes />
      <Toaster 
      toastOptions={toastConfig}
      />
    </Provider>
  );
}
