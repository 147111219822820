import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './user.css';

export default function User() {
  const me = useSelector((store) => store.me.me);

  return (
    <Link className="user" to="/meus-dados" title="Meus dados">
      {me.profile_image ? (
        <img src={me.profile_image} alt={me.name} />
      ) : (
        <span>{(me.name || '')[0]}</span>
      )}
    </Link>
  );
}
