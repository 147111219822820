import { useState, useEffect } from 'react';
import axios from 'axios';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import Select from 'components/Select';
import SelectMultiple from 'components/SelectMultiple';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import Upload from 'components/Upload';
import Loading, { Center } from 'components/Loading';
import toast from 'react-hot-toast';

import api from 'services/api';
import { formatDate, formatDateToSave } from 'services/utils';
import './voucher-form.css';

export default function Form({ selectedVoucher, setSelectedVoucher, fetchVouchers, onRequestClose }) {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [squareImage, setSquareImage] = useState('');
  const [rectangularImage1, setRectangularImage1] = useState('');
  const [rectangularImage2, setRectangularImage2] = useState('');
  const [rectangularImage3, setRectangularImage3] = useState('');
  const [rectangularImage4, setRectangularImage4] = useState('');
  const [onlineAddresses, setOnlineAddresses] = useState([]);
  const [physicalAddresses, setPhysicalAddresses] = useState([]);
  const [selectedOnlineAddresses, setSelectedOnlineAddresses] = useState([]);
  const [selectedPhysicalAddresses, setSelectedPhysicalAddresses] = useState([]);
  const [voucher, setVoucher] = useState({
    name: '',
    valid_from: '',
    valid_until: '',
    category_id: '',
    square_image: '',
    rectangular_image_1: '',
    rectangular_image_2: '',
    rectangular_image_3: '',
    rectangular_image_4: '',
    min_percent_off: '',
    max_percent_off: '',
    amount_off: '',
    description: '',
    physical_store: false,
    online_store: false,
    code: ''
  });

  useEffect(() => {
    if (selectedVoucher) {
      const [getOnlineAddresses, getPhysicalAddresses] = parseAddresses(selectedVoucher.addresses);

      setSelectedOnlineAddresses(getOnlineAddresses);
      setSelectedPhysicalAddresses(getPhysicalAddresses);

      setVoucher({
        name: selectedVoucher.name,
        valid_from: formatDate(selectedVoucher.valid_from),
        valid_until: formatDate(selectedVoucher.valid_until),
        category_id: selectedVoucher.category?.id,
        square_image: getImage(selectedVoucher.images, 0),
        rectangular_image_1: getImage(selectedVoucher.images, 1),
        rectangular_image_2: getImage(selectedVoucher.images, 2),
        rectangular_image_3: getImage(selectedVoucher.images, 3),
        rectangular_image_4: getImage(selectedVoucher.images, 4),
        min_percent_off: selectedVoucher.min_percent_off || '',
        max_percent_off: selectedVoucher.max_percent_off || '',
        amount_off: selectedVoucher.amount_off || '',
        description: selectedVoucher.description || '',
        physical_store: selectedVoucher.physical_store,
        online_store: selectedVoucher.online_store,
        code: selectedVoucher.code || ''
      });
    }

    return () => {
      setSelectedVoucher(null);
    }
  }, [selectedVoucher, setSelectedVoucher]);

  useEffect(() => {
    async function fetchCombobox() {
      const fetchAddreses = api.get('/v1/addresses');
      const fetchCategories = api.get('/v1/categories?all=true&type=vouchers');

      try {
        const [getAddresses, getCategories] = await Promise.all([fetchAddreses, fetchCategories]);

        // addresses
        const [getOnlineAddresses, getPhysicalAddresses] = parseAddresses(getAddresses.data);
        setOnlineAddresses(getOnlineAddresses);
        setPhysicalAddresses(getPhysicalAddresses);

        // categories
        setCategories(getCategories.data);
      } catch (error) {
        alert(error?.response?.data?.message || 'Não foi possivel buscar algumas informações!');
      } finally {
        setLoading(false);
      }
    }

    fetchCombobox();
  }, []);

  function getImage(images, index) {
    const image = images[index]
    return image ? image.url : '';
  }

  function parseAddresses(addresses) {
    let onlineAddresses = [];
    let physicalAddresses = [];

    for (const address of addresses) {
      if (address.type === 'online') {
        onlineAddresses.push({
          value: address.guid,
          label: address.title
        });
      }

      if (address.type === 'physical') {
        physicalAddresses.push({
          value: address.guid,
          label: address.title
        });
      }
    }

    return [onlineAddresses, physicalAddresses];
  }

  function formatAddressesToSave(arr) {
    return arr.flat().map(({ value }) => value);
  }

  function handleCancel() {
    onRequestClose();
  }

  function handleUpload(event) {
    const { name, files } = event.target;
    const [value] = files;

    if (name === 'square_image') {
      setSquareImage(value);
    }
    
    if (name === 'rectangular_image_1') {
      setRectangularImage1(value);
    }

    if (name === 'rectangular_image_2') {
      setRectangularImage2(value);
    }

    if (name === 'rectangular_image_3') {
      setRectangularImage3(value);
    }

    if (name === 'rectangular_image_4') {
      setRectangularImage4(value);
    }
  }

  function handleInput(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let voucherObj = {
      ...voucher
    };

    if (name === 'min_percent_off' || name === 'max_percent_off') {
      voucherObj.amount_off = '';
    }

    if (name === 'physical_store') {
      setSelectedPhysicalAddresses([]);
    }

    if (name === 'online_store') {
      voucherObj.code = '';
      setSelectedOnlineAddresses([]);
    }

    setVoucher({
      ...voucherObj,
      [name]: value
    });
  }

  function handleCurrencyInput(event, _, floatvalue) {
    const target = event.target;
    const name = target.name;

    let voucherObj = {
      ...voucher
    };

    if (name === 'amount_off') {
      voucherObj.min_percent_off = '';
      voucherObj.max_percent_off = '';
    }

    setVoucher({
      ...voucherObj,
      [name]: floatvalue
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    // body
    let body = {
      ...voucher,
      valid_from: formatDateToSave(voucher.valid_from),
      valid_until: formatDateToSave(voucher.valid_until),
      address_guids: formatAddressesToSave([selectedOnlineAddresses, selectedPhysicalAddresses])
    };

    // validations
    if (!voucher.physical_store && !voucher.online_store) {
      alert('Selecione se o voucher é loja física ou loja on-line');
      return;
    }

    if (body.address_guids.length === 0) {
      alert('Selecione um endereço.');
      return;
    }

    setSubmitting(true);

    try {
      if (squareImage) {
        try {
          const result = await api.get('/v1/file-upload?context=vouchers&extension=jpg');
          await axios.create().put(result.data.url, squareImage);

          body.square_image = result.data.url.split('?')[0];
        } catch (error) {
          setSubmitting(false);
          alert(`Desculpe, não foi possível fazer o upload da imagem!`);

          return;
        }
      }

      if (rectangularImage1) {
        try {
          const result = await api.get('/v1/file-upload?context=vouchers&extension=jpg');
          await axios.create().put(result.data.url, rectangularImage1);
          body.rectangular_image_1 = result.data.url.split('?')[0];
        } catch (error) {
          setSubmitting(false);
          alert(`Desculpe, não foi possível fazer o upload da imagem!`);
          return;
        }
      }
      if (rectangularImage2) {
        try {
          const result = await api.get('/v1/file-upload?context=vouchers&extension=jpg');
          await axios.create().put(result.data.url, rectangularImage2);
          body.rectangular_image_2 = result.data.url.split('?')[0];
        } catch (error) {
          setSubmitting(false);
          alert(`Desculpe, não foi possível fazer o upload da imagem!`);
          return;
        }
      }
      if (rectangularImage3) {
        try {
          const result = await api.get('/v1/file-upload?context=vouchers&extension=jpg');
          await axios.create().put(result.data.url, rectangularImage3);
          body.rectangular_image_3 = result.data.url.split('?')[0];
        } catch (error) {
          setSubmitting(false);
          alert(`Desculpe, não foi possível fazer o upload da imagem!`);
          return;
        }
      }
      if (rectangularImage4) {
        try {
          const result = await api.get('/v1/file-upload?context=vouchers&extension=jpg');
          await axios.create().put(result.data.url, rectangularImage4);
          body.rectangular_image_4 = result.data.url.split('?')[0];
        } catch (error) {
          setSubmitting(false);
          alert(`Desculpe, não foi possível fazer o upload da imagem!`);
          return;
        }
      }

      body.images = [];

      if (body.square_image) {
        body.images.push({
          url: body.square_image,
          type: 'square'
        });
      }
      if (body.rectangular_image_1) {
        body.images.push({
          url: body.rectangular_image_1,
          type: 'rectangular'
        });
      }
      if (body.rectangular_image_2) {
        body.images.push({
          url: body.rectangular_image_2,
          type: 'rectangular'
        });
      }
      if (body.rectangular_image_3) {
        body.images.push({
          url: body.rectangular_image_3,
          type: 'rectangular'
        });
      }
      if (body.rectangular_image_4) {
        body.images.push({
          url: body.rectangular_image_4,
          type: 'rectangular'
        });
      }

      delete body.square_image;
      delete body.rectangular_image_1;
      delete body.rectangular_image_2;
      delete body.rectangular_image_3;
      delete body.rectangular_image_4;

      if (selectedVoucher) {
        await api.put(`/v1/adm/vouchers/${selectedVoucher.guid}`, body);
      } else {
        await api.post('/v1/adm/vouchers', body);
      }
      toast.success('Salvo com sucesso');
    } catch (error) {
      setSubmitting(false);
      toast.error(error?.response?.data?.message || `Não foi possivel ${selectedVoucher ? 'atualizar' : 'criar'} o voucher!`);

      return;
    }

    setSubmitting(false);
    fetchVouchers();
    onRequestClose();
  }

  return (
    <div className="voucher-form-container">
      <div className="voucher-form-header">
        {selectedVoucher ? 'Editar' : 'Adicionar novo'} voucher
      </div>

      {loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <form className="voucher-form-body" onSubmit={handleSubmit}>
          <ul className="voucher-form-images">
            <li>
              <Upload
                name="square_image"
                onChange={handleUpload}
                value={voucher.square_image}
                image={squareImage}
                type="Formatos: JPG, PNG"
                size="(300px x 300px)"
                height={180}
              />
              {(squareImage || voucher.square_image) && (
                <span className="voucher-form-images-label">
                  (300px x 300px)
                </span>
              )}
            </li>
            <li>
              <Upload
                name="rectangular_image_1"
                onChange={handleUpload}
                value={voucher.rectangular_image_1}
                image={rectangularImage1}
                type="Formatos: JPG, PNG"
                size="(375px x 150px)"
                height={180}
              />
              {(rectangularImage1 || voucher.rectangular_image_1) && (
                <span className="voucher-form-images-label">
                  (375px x 150px)
                </span>
              )}
            </li>
            <li>
              <Upload
                name="rectangular_image_2"
                onChange={handleUpload}
                value={voucher.rectangular_image_2}
                image={rectangularImage2}
                type="Formatos: JPG, PNG"
                size="(375px x 150px)"
                height={180}
              />
              {(rectangularImage2 || voucher.rectangular_image_2) && (
                <span className="voucher-form-images-label">
                  (375px x 150px)
                </span>
              )}
            </li>
            <li>
              <Upload
                name="rectangular_image_3"
                onChange={handleUpload}
                value={voucher.rectangular_image_3}
                image={rectangularImage3}
                type="Formatos: JPG, PNG"
                size="(375px x 150px)"
                height={180}
              />
              {(rectangularImage3 || voucher.rectangular_image_3) && (
                <span className="voucher-form-images-label">
                  (375px x 150px)
                </span>
              )}
            </li>
            <li>
              <Upload
                name="rectangular_image_4"
                onChange={handleUpload}
                value={voucher.rectangular_image_4}
                image={rectangularImage4}
                type="Formatos: JPG, PNG"
                size="(375px x 150px)"
                height={180}
              />
              {(rectangularImage4 || voucher.rectangular_image_4) && (
                <span className="voucher-form-images-label">
                  (375px x 150px)
                </span>
              )}
            </li>
          </ul>

          <div className="voucher-form-fields">
            <div className="voucher-form-input">
              <ul className="voucher-form-radio-group">
                <li>
                  <Checkbox
                    label="Voucher loja física"
                    id="physical"
                    name="physical_store"
                    onChange={handleInput}
                    checked={voucher.physical_store}
                  />
                </li>
                <li>
                  <Checkbox
                    label="Voucher loja on-line"
                    id="online"
                    name="online_store"
                    onChange={handleInput}
                    checked={voucher.online_store}
                  />
                </li>
              </ul>
            </div>

            <div className="voucher-form-input">
              <Input
                label="Nome"
                name="name"
                onChange={handleInput}
                value={voucher.name}
                placeholder="Insira o título do seu voucher"
                required
              />
            </div>

            <div className="voucher-form-input voucher-form-columns">
              <div className="voucher-form-column-left">
                <Input
                  label="Data inicial"
                  name="valid_from"
                  onChange={handleInput}
                  value={voucher.valid_from}
                  placeholder="De"
                  mask="99/99/9999"
                  required
                />
              </div>

              <div className="voucher-form-column-right">
                <Input
                  label="Data final"
                  name="valid_until"
                  onChange={handleInput}
                  value={voucher.valid_until}
                  placeholder="Até"
                  mask="99/99/9999"
                  required
                />
              </div>
            </div>

            <div className="voucher-form-input">
              <Select
                label="Categoria"
                name="category_id"
                onChange={handleInput}
                value={voucher.category_id}
              >
                <option value="">Selecione</option>
                {categories.map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </Select>
            </div>

            {voucher.physical_store && (
              <div className="voucher-form-input">
                <SelectMultiple
                  label="Endereço"
                  placeholder="Selecione os endereços que seu voucher será aceito..."
                  items={selectedPhysicalAddresses}
                  setItems={setSelectedPhysicalAddresses}
                  data={physicalAddresses}
                  noItemsMessage="Nenhum endereço físico cadastrado"
                />
              </div>
            )}

            {voucher.online_store && (
              <div className="voucher-form-input">
                <SelectMultiple
                  label="Endereço on-line"
                  placeholder="Selecione os links que seu voucher será aceito..."
                  items={selectedOnlineAddresses}
                  setItems={setSelectedOnlineAddresses}
                  data={onlineAddresses}
                  noItemsMessage="Nenhum endereço on-line cadastrado"
                />
              </div>
            )}

            <div className="voucher-form-input voucher-form-columns">
              <div className="voucher-form-column-left">
                <div className="voucher-form-columns">
                  <div className="voucher-form-column-left">
                    <Input
                      label="% de desconto mínimo"
                      name="min_percent_off"
                      type="number"
                      min="1"
                      max="100"
                      onChange={handleInput}
                      value={voucher.min_percent_off}
                      placeholder="Insira a porcentagem"
                    />
                  </div>

                  <div className="voucher-form-column-right">
                    <Input
                      label="% de desconto máximo"
                      name="max_percent_off"
                      type="number"
                      min="1"
                      max="100"
                      onChange={handleInput}
                      value={voucher.max_percent_off}
                      placeholder="Insira a porcentagem"
                    />
                  </div>
                </div>
              </div>

              <div className="voucher-form-column-right">
                <Input
                  label="Valor"
                  name="amount_off"
                  onChangeEvent={handleCurrencyInput}
                  value={voucher.amount_off}
                  placeholder="Insira o valor do desconto"
                  currency={true}
                />
              </div>
            </div>

            {voucher.online_store && (
              <div className="voucher-form-input">
                <Input
                  label="Promo Code"
                  name="code"
                  onChange={handleInput}
                  value={voucher.code}
                  placeholder="Insira um código promocional para o voucher"
                />
              </div>
            )}

            <div className="voucher-form-input">
              <Textarea
                label="Descrição"
                name="description"
                onChange={handleInput}
                value={voucher.description}
                placeholder="Insira a descrição do seu voucher..."
                maxLength={120}
              />
            </div>

            <div className="voucher-form-actions">
              <Button type="button" onClick={handleCancel} disabled={submitting} outline>Cancelar</Button>
              <Button type="submit" loading={submitting}>Salvar</Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
