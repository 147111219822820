import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Page from 'components/Page';
import Input from 'components/Input';
import Button from 'components/Button';

import api from 'services/api';
import password from 'assets/images/password.svg';

export default function ResetPassword() {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');

  function handleBack() {
    setEmail('');
    setSuccess(false);
  }

  function handleEmail(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitting(true);

    const body = {
      email
    };

    api.post('/v1/reset-password', body)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        alert(error?.response?.data?.message || 'Não foi possivel redefinir sua senha, tente novamente!');
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return(
    <Page>
      <div className="container">
        <div className="reset">
          {success ? (
            <div className="reset-info">
              <h1 className="title">
                Verifique seu e-mail para obter instruções de como redefinir a senha
              </h1>
              <p>
                Não recebeu o e-mail?<br /> Confira a pasta de spam ou <span className="link" onClick={handleBack}>solicite outro</span>.
              </p>

              <div className="reset-info-actions">
                <Button onClick={() => history.push('/')} outline>Home</Button>
              </div>
            </div>
          ) : (
            <>
              <div className="reset-info">
                <img src={password} alt="" />
                <h1 className="title">Redefina sua senha</h1>
                <p>Informe o e-mail associado à sua conta para receber um link de redefinição de senha.</p>
              </div>

              <form className="reset-form" onSubmit={handleSubmit}>
                <div className="reset-input">
                  <Input
                    label="E-mail"
                    name="email"
                    type="email"
                    onChange={handleEmail}
                    value={email}
                    required
                  />
                </div>

                <div className="reset-actions">
                  <Button loading={submitting}>Continuar</Button>
                  <Link to="/login">Voltar para o login</Link>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </Page>
  );
}
