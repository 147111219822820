import axios from 'axios';
import baseURL from './api-url';
import { getToken } from './auth';

const api = axios.create({
  baseURL: `https://${baseURL}`
});

const token = getToken();

if (token) {
  api.defaults.headers.common = {
    Authorization: `Bearer ${token}`
  };
}

export default api;
