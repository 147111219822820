import { useRef } from 'react';
import './select.css';

export default function Select({ children, className, label, ...props }) {
  const textInput = useRef(null);

  function handleClick() {
    textInput.current.focus();
  }

  return (
    <div className="select">
      {label && <label onClick={handleClick}>{label}</label>}
      <select className={`select${className ? ` ${className}` : ''}`} {...props} ref={textInput}>
        {children}
      </select>
    </div>
  );
}
