import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Page from 'components/Page';
import Button from 'components/Button';

import * as MeActions from 'store/modules/me/actions';
import api from 'services/api';

import alertImage from 'assets/images/alert.svg';
import './cancel-partnership.css';

export default function CancelPartnership() {
  const history = useHistory();
  const me = useSelector((store) => store.me.me);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      if (success) {
        dispatch(MeActions.me({
          ...me,
          cancellation_request: true
        }));
      }
    }
  }, [dispatch, me, success]);

  function handleCancel() {
    setSubmitting(true);

    api.post('/v1/adm/me/cancel')
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        alert(error?.response?.data?.message || 'Não conseguimos enviar seus dados, tente novamente!');
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function goToProfile() {
    history.push('/meus-dados');
  }

  return (
    <Page>
      <div className="container">
        <div className="cancel-partnership-content">
          <div className="cancel-partnership-box">
            {me?.cancellation_request ? (
              <>
                <div className="cancel-partnership-text">
                  <h1>Você já efetuou a solicitação de cancelamento.</h1>
                  <p>Em breve entraremos em contato para concluir o cancelamento.</p>
                </div>

                <div className="cancel-partnership-actions">
                  <Button onClick={goToProfile}>Ok</Button>
                </div>
              </>
            ) : success ? (
              <>
                <div className="cancel-partnership-text">
                  <h1>Seu cancelamento foi solicitado.</h1>
                  <p>Em breve entraremos em contato para concluir o cancelamento.</p>
                </div>

                <div className="cancel-partnership-actions">
                  <Button onClick={goToProfile}>Ok</Button>
                </div>
              </>
            ) : (
              <>
                <div className="cancel-partnership-image">
                  <img src={alertImage} alt="Cancelar parceria"/>
                </div>

                <div className="cancel-partnership-text">
                  <h1>Tem certeza que deseja cancelar sua parceria no Clube de Vantagens Unicred?</h1>
                  <p>Ao cancelar, seus vouchers não aparecerão mais no APP.</p>
                </div>

                <div className="cancel-partnership-actions">
                  <Button type="button" onClick={handleCancel} loading={submitting} outline>Sim</Button>
                  <Button type="button" onClick={goToProfile} disabled={submitting}>Não</Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}