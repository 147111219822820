import './text.css';

export default function Text() {
  return (
    <div className="terms-content">
      <div className="terms-container">
        <h1>TERMO DE ADESÃO – CLUBE DE VANTAGENS</h1>

        <h2>CLAUSULA 1ª – DA QUALIFICAÇÃO DAS PARTES</h2>

        <p><strong>CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA.</strong>, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 00.315.557/0002-00, com sede na rua Dezoito de Novembro, n.º 314, Porto Alegre/RS, CEP: 90240-040, neste ato representada por <strong>ALEJANDRO DANIEL HALON</strong>, portador do RNE n.º v18416417 e CPF nº 803.609.390-15, domiciliado na rua Ouro Preto, nº 417, Porto Alegre/RS, CEP: 91040-610, doravante denominada “<strong>CONVENENTE</strong>”;</p>

        <p><strong>PESSOA FÍSICA ou JURÍDICA</strong>, devidamente previamente cadastrada no sistema, que leu e está de acordo com o conteúdo do presente instrumento, inclusive emitindo seu aceite na forma eletrônica, doravante denominado(a) “<strong>CONVENIADO(A)</strong>” e;</p>

        <p><strong>HALLO SERVIÇOS DE INTERNET LTDA.</strong>, pessoa jurídica de direito privado, inscrita no CNPJ sob o n.º 25.300.256/0001-45, com sede na rua Namy Deeke, n.º 99, Sala 401, Centro, Blumenau/SC, CEP: 89010-130, neste ato representada por seu sócio administrador, <strong>BRUNO HENRIQUE GRAHL</strong>, brasileiro, empresário, casado, portador do CPF sob n.º 046.444.659-76, residente e domiciliado na rua Ricardo Paul, n.º 165, apto 805, bairro Asilo, Blumenau/SC, CEP: 89037-680, doravante denominada simplesmente “<strong>GESTORA</strong>”.</p>

        <h2>CLAUSULA 2ª – DO OBJETO</h2>

        <p>O presente instrumento tem por objeto a vinculação, ao sistema “<strong>Clube de Vantagens</strong>” da CONVENENTE, da razão social; nome fantasia; logos; marcas; endereço; contato; e/ou outras informações relacionadas ao(à) <strong>CONVENIADO(A)</strong>, com o intuito de conferir publicidade ao negócio deste(a) (compatível com os limites do referido sistema) e, em contra partida, assegurar benefícios especiais aos usuários que mantenham vínculo com a <strong>CONVENENTE</strong>.</p>

        <h2>CLAUSULA 3ª – DO SISTEMA E BENEFÍCIOS</h2>

        <p>O sistema “Clube de Vantagens” se trata de plataforma destinada a divulgação de ofertas, descontos e outras vantagens especiais disponibilizadas por <strong>CONVENIADOS</strong> aos usuários do sistema vinculados à <strong>CONVENENTE</strong>.</p>

        <p><strong>Parágrafo primeiro:</strong> Em contraprestação à publicidade realizada por meio do sistema, o(a) <strong>CONVENIADO(A)</strong> assegurará aos usuários cadastrados no sistema, de forma irrestrita, vantagens/ofertas/descontos ou outros benefícios anunciados por meio da plataforma.</p>

        <p className="text-underline"><strong>Parágrafo segundo: Durante toda a vigência do presente instrumento, as partes definirão, em comum acordo, quais serão os benefícios noticiados aos usuários do sistema, prazo de duração, condições e demais pormenores de promoções, o que deverá ocorrer por meio do canal de comunicação oficial (Cláusula 9ª).</strong></p>

        <h2>CLAUSULA 4ª – DA PROPRIEDADE INTELECTUAL</h2>

        <p><strong>O presente contrato não implica em transmissão ao(à) CONVENIADO(A) de nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como relacionados a todo o conteúdo disponibilizado por meio do sistema, incluindo – mas não se limitando – a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material.</strong></p>

        <h2>CLAUSULA 5ª – DO PRAZO E RESILIÇÃO</h2>

        <p>O presente instrumento permanecerá em vigor por prazo indeterminado.</p>

        <p><strong>Parágrafo único:</strong> Quaisquer das partes poderá optar pela resilição contratual, dispensada justificação, bastando que notifique a parte contrária acerca do seu desinteresse na continuidade da relação contratual (por meio do canal oficial de comunicação – Cláusula 9ª) <strong className="text-underline">com antecedência mínima de 30 (trinta) dias.</strong></p>

        <p><strong>I</strong> – O presente instrumento também será resilido, imediatamente e independentemente de notificação, na hipótese em que haja o encerramento (por qualquer hipótese) de vínculo contratual entre a <strong>CONVENENTE</strong> e a <strong>GESTORA</strong>, sem que isso importe em qualquer direito de indenização ao(à) <strong>CONVENIADO(A)</strong>.</p>

        <h2>CLAUSULA 6ª – DA AUTORIZAÇÃO PARA TRATAMENTO DE DADOS – LEI Nº 13.709/2018</h2>

        <p>No desenvolvimento de quaisquer atividades relacionadas ao objeto do presente contrato, a <strong>CONVENENTE</strong> e a <strong>GESTORA</strong> comprometem-se a cumprir o regime legal da proteção de dados pessoais, em especial as disposições previstas na Lei 13.709/2018 (Lei Geral de Proteção de Dados), conforme estas entrarem em vigor.</p>

        <p><strong>Parágrafo primeiro:</strong> A <strong>CONVENENTE</strong> e a <strong>GESTORA</strong> comprometem-se a adotar medidas técnicas e organizacionais adequadas para garantir a segurança das informações pessoais e demais dados aos quais tiverem acesso em decorrência do presente contrato.</p>

        <p><strong>Parágrafo segundo:</strong> <strong className="text-underline">O(A) CONVENIADO(A) autoriza a CONVENENTE e a GESTORA a armazenar, usar, compartilhar ou comercializar quaisquer eventuais elementos de dados que se originem ou sejam criados a partir do tratamento de Dados Pessoais relacionados à esta contratação.</strong></p>

        <p><strong>I</strong> – A <strong>CONVENENTE</strong> e a <strong>GESTORA</strong> ficam desde já autorizados, também, a fornecer quaisquer dados relacionados direta ou indiretamente ao(a) <strong>CONVENIADO(A)</strong> às autoridades judiciais ou administrativas, quando intimados/notificados a fazê-lo por estas.</p>

        <p><strong>Parágrafo terceiro:</strong> A qualquer momento, poderá o(a) <strong>CONVENIADO(A)</strong> revogar a autorização descrita no parágrafo anterior, bastando que se manifeste expressamente e por escrito, dirigindo-se à <strong>GESTORA</strong> por meio do canal de comunicação oficial (Cláusula 9ª).</p>

        <p><strong>I –</strong> <strong className="text-underline">Eventual revogação total ou parcial da autorização prevista no Parágrafo segundo desta Cláusula poderá implicar na inviabilidade parcial ou total de utilização do sistema por parte do(a) CONVENIADO(A), bem como não implicará em qualquer efeito aos atos relacionados praticados anteriormente à revogação, permanecendo estes hígidos, isentando-se a CONVENENTE e a GESTORA de qualquer responsabilidade.</strong></p>

        <h2>CLAUSULA 7ª – DA ATUALIZAÇÃO DO SISTEMA</h2>

        <p>É autorizado à <strong>GESTORA</strong>, a qualquer tempo, promover atualizações ao sistema independentemente de consentimento prévio das partes, sempre visando a implementação de novas funções, melhorias estéticas ou de funcionamento geral.</p>

        <h2>CLAUSULA 8ª – DA APROVAÇÃO DO CONTEÚDO</h2>

        <p>O(A) <strong>CONVENIADO(A)</strong> concorda que todo o conteúdo que pretender vincular ao sistema deverá ser previamente aprovado pela <strong>GESTORA</strong>, sendo que o envio e análise para aprovação ocorrerá por meio de funcionalidade própria do sistema.</p>

        <h2>CLAUSULA 9ª – DO CANAL OFICIAL DE COMUNICAÇÃO</h2>

        <p>As partes concordam que, inicialmente, utilizarão como canal de comunicação oficial os seguintes respectivos endereços de e-mail:</p>

        <p><strong>GESTORA / CONVENENTE</strong> – clubedevantagens@unicred.com.br</p>

        <p><strong>CONVENIADO(A)</strong> – endereço de e-mail informado na oportunidade do cadastramento no sistema.</p>

        <p><strong>Parágrafo único:</strong> <strong className="text-underline">A critério da GESTORA, esta poderá definir novos canais de comunicação oficial, mormente em caso de implementação de funcionalidades no sistema que viabilizem tal comunicação entre as partes.</strong></p>

        <p><strong>I</strong> – Para implementação de novos canais de comunicação oficial, em substituição ou complemento aos constantes neste instrumento, deverá a <strong>GESTORA</strong> notificar as partes, por meio do canal até então utilizado, com antecedência razoável.</p>

        <h2>CLAUSULA 10ª – DO FORO</h2>

        <p>Este instrumento é regido pelas leis brasileiras, sendo que as partes desde logo elegem, de forma irrevogável e irretratável, o foro da Comarca de Blumenau/SC para dirimir quaisquer controvérsias relacionadas ao presente, com a exclusão de qualquer outro, por mais privilegiado que seja.</p>

        <p className="text-underline"><strong>POR CONCORDAR COM TODOS OS TERMOS E OBRIGAÇÕES CONSTANTES NESTE INSTRUMENTO, O(A) CONVENIADO(A) EMITE SEU ACEITE, NA FORMA ELETRÔNICA, PARA QUE PASSE O PRESENTE INSTRUMENTO A SURTIR SEUS EFEITOS DE DIREITO.</strong></p>
      </div>
    </div>
  );
}
