import { useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Nav from './Nav';
import User from './User';

import * as MeActions from 'store/modules/me/actions';
import { removeToken } from 'services/auth';

import arrow from 'assets/images/arrow.svg';
import logo from 'assets/images/logo.svg';
import menuArrow from 'assets/images/menu-arrow.svg';
import './menu.css';
import './header.css';

export default function Header() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [visibleMenu, setVisibleMenu] = useState(false);

  const isHome = location.pathname === '/';
  const isLogin = location.pathname === '/login';
  const isResetPassword = location.pathname === '/redefinir-senha';
  const isChangePassword = location.pathname === '/alterar-senha';
  const isPartner = location.pathname === '/seja-um-parceiro';
  const isPartnerList = location.pathname === '/parceiros';

  function handleVisibleMenu() {
    setVisibleMenu(!visibleMenu);
  }

  function handleLogout() {
    removeToken();
    dispatch(MeActions.me({}));
    history.push(`/`);
  }

  return (
    <header className="header">
      <div className="container">
        <div className={`header-logo${isLogin || isResetPassword || isChangePassword ? ' center' : ''}`}>
          <a href="https://www.unicred.com.br/home">
            <img src={logo} alt="Unicred Clube" />
          </a>
        </div>

        {!isLogin && !isResetPassword && !isChangePassword && (
          <div className={`header-right${visibleMenu ? ' visible' : ''}`}>
            <div className="header-menu">
              {(isHome || isPartnerList) && (
                <Nav />
              )}
            </div>

            {(isHome || isPartnerList) && (
              <div className="header-actions">
                <Link className="header-button" to="/login">
                  Sou parceiro
                </Link>
                <Link className="header-button outline" to="/seja-um-parceiro">
                  Seja um parceiro
                </Link>
              </div>
            )}

            {!isHome && !isPartnerList && !isPartner && (
              <div className="header-authorized">
                <ul className="header-authorized-menu">
                  <li>
                    <Link to="/">
                      <span>Home</span>
                    </Link>
                  </li>
                </ul>

                <div className="header-authorized-user">
                  <User />

                  <div>
                    <Menu
                      align="end"
                      menuButton={({ open }) => (
                        <MenuButton className={open ? 'rc-menu-button--active' : ''}>
                          <img src={menuArrow} alt="" />
                        </MenuButton>
                      )}
                    >
                      <MenuItem onClick={handleLogout}>Sair</MenuItem>
                      <MenuItem onClick={() => history.push('/cancelar-parceria')}>Cancelar parceria</MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            )}

            {isPartner && (
              <div className="header-authorized">
                <ul className="header-authorized-menu">
                  <li>
                    <Link to="/">
                      <span>Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/seja-um-parceiro" className="active">
                      <span>Seja um parceiro</span>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>

      {!isLogin && !isResetPassword && !isChangePassword && (
        <div className={`header-menu-button${visibleMenu ? ' active' : ''}`} onClick={handleVisibleMenu}>
          <img src={arrow} alt="" />
        </div>
      )}
    </header>
  );
}
