import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Page from 'components/Page';
import AdmPage from 'components/AdmPage';
import Terms from 'components/Terms';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import Upload from 'components/Upload';

import * as MeActions from 'store/modules/me/actions';
import api from 'services/api';
import toast from 'react-hot-toast';

import './profile.css';

export default function Profile() {
  const me = useSelector((store) => store.me.me);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [logo, setLogo] = useState('');
  const [squareLogo, setSquareLogo] = useState('');
  const [terms, setTerms] = useState(false);
  const [form, setForm] = useState({
    profile_image: '',
    square_profile_image: '',
    name: '',
    email: '',
    about: '',
    username: '',
    password: '',
    accept_terms: false
  });

  useEffect(() => {
    if (me) {
      setTerms(me.accept_terms);
      setForm({
        profile_image: me.profile_image || '',
        square_profile_image: me.square_profile_image || '',
        name: me.name || '',
        email: me.email || '',
        about: me.about || '',
        username: me.username || '',
        password: '',
        accept_terms: me.accept_terms
      });
    }
  }, [me]);

  function handleTerms(event) {
    setTerms(event.target.checked);
  }

  function handleInput(event) {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value
    });
  }

  function handleLogo(event) {
    const [value] = event.target.files;
    setLogo(value);
  }

  function handleSquareLogo(event) {
    const [value] = event.target.files;
    setSquareLogo(value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmitting(true);

    let body = {
      ...form,
      accept_terms: terms
    };

    if (logo) {
      try {
        const result = await api.get('/v1/file-upload?context=profile&extension=jpg');
        await axios.create().put(result.data.url, logo);

        body.profile_image = result.data.url.split('?')[0];
      } catch (error) {
        setSubmitting(false);
        alert(`Desculpe, não foi possível fazer o upload do logotipo!`);

        return;
      }
    }

    if (squareLogo) {
      try {
        const result = await api.get('/v1/file-upload?context=profile&extension=jpg');
        await axios.create().put(result.data.url, squareLogo);

        body.square_profile_image = result.data.url.split('?')[0];
      } catch (error) {
        setSubmitting(false);
        alert(`Desculpe, não foi possível fazer o upload da logotipo!`);

        return;
      }
    }

    api.put('/v1/adm/me', body)
      .then(({ data: me }) => {
        dispatch(MeActions.me(me));
        toast.success('Salvo com sucesso');
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Não foi possivel atualizar suas informações!');
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <Page>
      <AdmPage>
        <div className="profile-header">
          <h1 className="title">Meus dados</h1>
        </div>

        <form className="profile-body" onSubmit={handleSubmit}>
          <div className="profile-image">
            <div className="profile-form-input">
              <Upload
                name="profile_image"
                onChange={handleLogo}
                value={form.profile_image}
                image={logo}
                type="Formatos: JPG, PNG"
                size="(375px x 150px)"
                height={175}
              />
            </div>

            <div>
              <Upload
                name="square_profile_image"
                onChange={handleSquareLogo}
                value={form.square_profile_image}
                image={squareLogo}
                type="Formatos: JPG, PNG"
                size="(300px x 300px)"
                height={175}
              />
            </div>
          </div>

          <div className="profile-form">
            <div className="profile-form-input">
              <Input
                label="Nome"
                name="name"
                onChange={handleInput}
                value={form.name}
                required
              />
            </div>

            <div className="profile-form-input">
              <Input
                label="E-mail"
                name="email"
                type="email"
                onChange={handleInput}
                value={form.email}
                required
              />
            </div>

            <div className="profile-form-input">
              <Textarea
                label="Sobre"
                name="about"
                onChange={handleInput}
                value={form.about}
                maxLength={120}
              />
            </div>

            <div className="profile-form-input">
              <Input
                label="Login"
                name="username"
                onChange={handleInput}
                value={form.username}
                required
              />
            </div>

            <div className="profile-form-input">
              <Input
                label="Senha"
                name="password"
                type="password"
                onChange={handleInput}
                value={form.password}
              />
            </div>

            <div>
              <Checkbox
                label={<Terms />}
                id="accept_terms"
                name="accept_terms"
                checked={terms}
                disabled={form.accept_terms}
                onChange={handleTerms}
              />
            </div>

            <div className="profile-form-info">
              * Para que seus vouchers apareçam no Clube, você precisa ler e aceitar o Termo de Adesão.
            </div>

            <div className="profile-form-actions">
              <Button type="submit" loading={submitting}>Salvar</Button>
            </div>
          </div>
        </form>
      </AdmPage>
    </Page>
  );
}
