import { useState, useEffect, useCallback } from 'react';
import Page from 'components/Page';
import AdmPage from 'components/AdmPage';
import Modal from 'components/Modal';
import ConfirmationModal from 'components/ConfirmationModal';
import Button from 'components/Button';
import ButtonAction from 'components/ButtonAction';
import Note from 'components/Note';
import Loading, { Center } from 'components/Loading';
import Form from './Form';

import api from 'services/api';
import './addresses.css';

export default function Addresses() {
  const filters = [
    { value: 'physical', label: 'Endereços Físicos' },
    { value: 'online', label: 'Endereços Online' }
  ];
  const defaultFilter = filters[0].value;
  const [filter, setFilter] = useState(defaultFilter);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addresses, setAddresses] = useState([]);

  const fetchAddresses = useCallback(() => {
    setLoading(true);

    api.get(`/v1/addresses?type=${filter}`)
      .then(({ data: addresses }) => {
        setAddresses(addresses);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || 'Não foi possivel buscar os endereços!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  function handleNewAddress() {
    handleModal();
  }

  function handleEditAddress(address) {
    setSelectedAddress(address);
    handleModal();
  }

  function handleDeleteAddress(address) {
    setSelectedAddress(address);
    setConfirmationModal(true);
  }

  function deleteAddress(guid) {
    return api.delete(`/v1/addresses/${guid}`);
  }

  function handleFilter(value) {
    if (value === filter) return;
    setFilter(value);
  }

  function handleModal() {
    setModalIsOpen(!modalIsOpen);
  }

  return (
    <Page>
      <AdmPage>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleModal}
        >
          <Form
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            filter={filter}
            fetchAddresses={fetchAddresses}
            setFilter={setFilter}
            onRequestClose={handleModal}
          />
        </Modal>

        <ConfirmationModal
          title="Excluir endereço"
          description={`Tem certeza que deseja excluir o endereço "${selectedAddress?.title}"?`}
          modalIsOpen={confirmationModal}
          setModalIsOpen={setConfirmationModal}
          submit={() => deleteAddress(selectedAddress?.guid)}
          onSuccess={fetchAddresses}
          onRequestClose={() => setSelectedAddress(null)}
        />

        <div className="addresses-header">
          <div className="addresses-header-info">
            <h1 className="title">Endereços Cadastrados</h1>
            <p>Veja seus endereços já cadastrados abaixo:</p>
          </div>

          <div className="addresses-header-actions">
            <Button onClick={handleNewAddress} outline>Novo Endereço</Button>
          </div>
        </div>

        <ul className="tab-filter">
          {filters.map(({ value, label }) => (
            <li
              key={value}
              className={value === filter ? 'active' : ''}
              onClick={() => handleFilter(value)}
            >
              {label}
            </li>
          ))}
        </ul>

        <div className="addresses-body">
          {loading ? (
            <Center>
              <Loading />
            </Center>
          ) : error ? (
            <Note>{error}</Note>
          ) : addresses.length === 0 ? (
            <Note>Nenhum endereço cadastrado ainda!</Note>
          ) : (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Título</th>
                    {filter === defaultFilter ? (
                      <>
                        <th>Logradouro</th>
                        <th>Número</th>
                        <th>Bairro</th>
                        <th>Cidade</th>
                        <th>CEP</th>
                      </>
                    ) : (
                      <th>Link</th>
                    )}
                    <th>Opções</th>
                  </tr>
                </thead>

                <tbody>
                  {addresses.map((address) => (
                    <tr key={address.guid}>
                      <td>{address.title}</td>
                      {filter === defaultFilter ? (
                        <>
                          <td>{address.street}</td>
                          <td>{address.number}</td>
                          <td>{address.neighborhood}</td>
                          <td>{address.city?.name}</td>
                          <td>{address.zipcode}</td>
                        </>
                      ) : (
                        <td>{address.link}</td>
                      )}
                      <td className="table-actions">
                        <div className="table-actions-container">
                          <ButtonAction action="edit" title="Editar" onClick={() => handleEditAddress(address)} />
                          <ButtonAction action="delete" title="Deletar" onClick={() => handleDeleteAddress(address)} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </AdmPage>
    </Page>
  );
}
