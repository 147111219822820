import format from 'date-fns/format';
import getTime from 'date-fns/getTime';

export function formatDate(date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return format(date, 'dd/MM/yyyy');
}

export function formatDateToSave(datetime) {
  const [date] = datetime.split(' ');
  const newDate = `${date.split('/').reverse().join('-')} 00:00:00`;

  return getTime(new Date(newDate));
}
