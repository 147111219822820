import { useState, useEffect } from 'react';
import axios from 'axios';
import Page from 'components/Page';
import Terms from 'components/Terms';
import Input from 'components/Input';
import Radio from 'components/Radio';
import Select from 'components/Select';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import Upload from 'components/Upload';
import Loading, { Center } from 'components/Loading';
import {Link} from 'react-router-dom';
import api from 'services/api';
import success from 'assets/images/success.png';
import './partner.css';
import Checkbox from 'components/Checkbox';

export default function Partner() {
  const [step, setStep] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [logo, setLogo] = useState('');
  const [squareLogo, setSquareLogo] = useState('');
  const [partner, setPartner] = useState({
    name: '',
    cnpj: '',
    email: '',
    about: '',
    logo: '',
    square_logo: '',
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    accept_terms: false,
    social_facebook: '',
    social_linkedin: '',
    social_instagram: '',
    is_cooperated: 'yes'
  });
  const [address, setAddress] = useState({
    title: '',
    street: '',
    number: '',
    neighborhood: '',
    zipcode: '',
    city_id: '',
    link: '',
    lat: '',
    lng: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    api.get('/v1/cities')
      .then(({ data: cities }) => {
        setCities(cities);
      })
      .catch((error) => {
        alert(error?.response?.data?.message || 'Não foi possivel buscar as cidades!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleBack() {
    setStep(step - 1);
  }

  function handlePartner(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setPartner({
      ...partner,
      [name]: value
    });
  }

  function handleAddress(event) {
    const { name, value } = event.target;
    setAddress({ ...address, [name]: value });
  }

  function handleLogo(event) {
    const [value] = event.target.files;
    setLogo(value);
  }

  function handleSquareLogo(event) {
    const [value] = event.target.files;
    setSquareLogo(value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (step === 2) {
      setSubmitting(true);

      let body = {
        partner: {
          ...partner,
          is_cooperated: partner.is_cooperated === 'yes'
        },
        address
      };

      if (logo) {
        try {
          const result = await api.get('/v1/file-upload?context=profile&extension=jpg');
          await axios.create().put(result.data.url, logo);

          body.partner.logo = result.data.url.split('?')[0];
        } catch (error) {
          setSubmitting(false);
          alert(`Desculpe, não foi possível fazer o upload do logotipo!`);

          return;
        }
      }

      if (squareLogo) {
        try {
          const result = await api.get('/v1/file-upload?context=profile&extension=jpg');
          await axios.create().put(result.data.url, squareLogo);

          body.partner.square_logo = result.data.url.split('?')[0];
        } catch (error) {
          setSubmitting(false);
          alert(`Desculpe, não foi possível fazer o upload do logotipo!`);

          return;
        }
      }

      api.post('/v1/partners', body)
        .then(() => {
          setStep(step + 1);
        })
        .catch((error) => {
          alert(error?.response?.data?.message || 'Não conseguimos enviar seus dados, tente novamente!');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      if (!logo || !squareLogo) {
        alert('Logotipo obrigatório!');
        return;
      }

      if (!partner.accept_terms) {
        alert('É necessário aceitar os termos de adesão para prosseguir.');
        return;
      }

      setStep(step + 1);
    }
  }

  return (
    <Page>
      <div className="container">
        <div className="partner-body">
          <form onSubmit={handleSubmit}>
            {step === 1 ? (
              <div id="step-1">
                <div className="partner-header">
                  <h1 className="title">Seja um parceiro!</h1>
                  <p>Aproveite todos os benefícios que o Clube de Vantagens tem a oferecer.</p>
                </div>

                <div className="partner-line" />

                <div className="partner-header">
                  <h1 className="title">Preencha os dados abaixo:</h1>
                  <p>Dados do estabelecimento:</p>
                </div>

                <div className="partner-fields">
                  <div className="partner-input">
                    <Input
                      label="Nome"
                      name="name"
                      onChange={handlePartner}
                      value={partner.name}
                      placeholder="Insira o nome do seu estabelecimento"
                      required
                    />
                  </div>
                  <div className="partner-input">
                    <Input
                      label="CNPJ"
                      name="cnpj"
                      onChange={handlePartner}
                      value={partner.cnpj}
                      placeholder="000.000.000-00"
                      mask="99.999.999/9999-99"
                      required
                    />
                  </div>
                  <div className="partner-input">
                    <Input
                      label="E-mail"
                      name="email"
                      type="email"
                      onChange={handlePartner}
                      value={partner.email}
                      placeholder="Insira o e-mail comercial"
                      required
                    />
                  </div>
                  <div className="partner-input">
                    <Textarea
                      label="Sobre"
                      name="about"
                      onChange={handlePartner}
                      value={partner.about}
                      placeholder="Insira informações que você ache importante sobre seu estabelecimento..."
                      maxLength={120}
                    />
                  </div>
                  <div className="partner-input partner-columns">
                    <div className="partner-column-left-upload">
                      <Upload
                        name="logo"
                        onChange={handleLogo}
                        value=""
                        image={logo}
                        info={{
                          title: 'LOGOTIPO',
                          type: 'Carregar arquivo (.jpg ou .png)',
                          size: 'Tamanho 375px x 150px'
                        }}
                        height={120}
                      />
                    </div>

                    <div className="partner-column-right-upload">
                      <Upload
                        name="square_logo"
                        onChange={handleSquareLogo}
                        value=""
                        image={squareLogo}
                        info={{
                          title: 'LOGOTIPO',
                          type: 'Carregar arquivo (.jpg ou .png)',
                          size: 'Tamanho 300px x 300px'
                        }}
                        height={120}
                      />
                    </div>
                  </div>
                </div>

                <div className="partner-header">
                  <p>Dados para contato:</p>
                </div>

                <div className="partner-fields">
                  <div className="partner-input">
                    <Input
                      label="Nome"
                      name="contact_name"
                      onChange={handlePartner}
                      value={partner.contact_name}
                      placeholder="Insira o nome do responsável"
                      required
                    />
                  </div>
                  <div className="partner-input">
                    <Input
                      label="Telefone"
                      name="contact_phone"
                      onChange={handlePartner}
                      value={partner.contact_phone}
                      placeholder="(00) 0 0000-0000"
                      required
                    />
                  </div>
                  <div className="partner-input">
                    <Input
                      label="E-mail"
                      name="contact_email"
                      type="email"
                      onChange={handlePartner}
                      value={partner.contact_email}
                      placeholder="Insira um e-mail para contato"
                      required
                    />
                  </div>
                  <div className="partner-input">
                    <Checkbox
                      label={<Terms />}
                      id="accept_terms"
                      name="accept_terms"
                      checked={partner.accept_terms}
                      onChange={handlePartner}
                    />
                  </div>
                </div>

                <div className="partner-fields">
                  <div className="partner-actions">
                    <Link to="/" className="partner-link">
                      Voltar
                    </Link>

                    <button className="partner-button" type="submit">
                      Próximo
                    </button>
                  </div>
                </div>
              </div>
            ) : step === 2 ? (
              <div id="step-2">
                {loading ? (
                  <Center>
                    <Loading />
                  </Center>
                ) : (
                  <>
                    <div className="partner-header">
                      <h1 className="title">Endereço</h1>
                      <p>Dados do endereço do seu estabelecimento:</p>
                    </div>

                    <div className="partner-fields">
                      <div className="partner-input">
                        <Input
                          label="Título"
                          name="title"
                          onChange={handleAddress}
                          value={address.title}
                          placeholder="Insira o nome do seu estabelecimento"
                          required
                        />
                      </div>

                      <div className="partner-input partner-columns">
                        <div className="partner-column-left">
                          <Input
                            label="Logradouro"
                            name="street"
                            onChange={handleAddress}
                            value={address.street}
                            placeholder="Insira o logradouro"
                            required
                          />
                        </div>

                        <div className="partner-column-right">
                          <Input
                            label="Número"
                            name="number"
                            type="number"
                            onChange={handleAddress}
                            value={address.number}
                            placeholder="Insira o número"
                            required
                          />
                        </div>
                      </div>

                      <div className="partner-input partner-columns">
                        <div className="partner-column-left">
                          <Input
                            label="Bairro"
                            name="neighborhood"
                            onChange={handleAddress}
                            value={address.neighborhood}
                            placeholder="Insira o bairro"
                            required
                          />
                        </div>

                        <div className="partner-column-right">
                          <Input
                            label="CEP"
                            name="zipcode"
                            onChange={handleAddress}
                            value={address.zipcode}
                            placeholder="Insira o CEP"
                            mask="99999-999"
                            required
                          />
                        </div>
                      </div>

                      <div className="partner-input">
                        <Select
                          label="Cidade"
                          name="city_id"
                          onChange={handleAddress}
                          value={address.city_id}
                        >
                          <option value="">Insira a cidade</option>
                          {cities.map(({ id, name }) => (
                            <option key={id} value={id}>{name}</option>
                          ))}
                        </Select>
                      </div>

                      <div className="partner-input">
                        <Input
                          label="Link"
                          name="link"
                          type="url"
                          onChange={handleAddress}
                          value={address.link}
                          placeholder="Insira o link da sua loja"
                        />
                      </div>
                    </div>

                    <div className="partner-header">
                      <h1 className="title">Redes Sociais</h1>
                      <p>Preencha com as redes sociais do seu estabelecimento:</p>
                    </div>

                    <div className="partner-fields">
                      <div className="partner-input">
                        <Input
                          label="Facebook"
                          name="social_facebook"
                          onChange={handlePartner}
                          value={partner.social_facebook}
                          placeholder="Insira o link do Facebook"
                        />
                      </div>
                      <div className="partner-input">
                        <Input
                          label="Linkedin"
                          name="social_linkedin"
                          onChange={handlePartner}
                          value={partner.social_linkedin}
                          placeholder="Insira o link do Linkedin"
                        />
                      </div>
                      <div className="partner-input">
                        <Input
                          label="Instagram"
                          name="social_instagram"
                          onChange={handlePartner}
                          value={partner.social_instagram}
                          placeholder="Insira o link do Instagram"
                        />
                      </div>
                    </div>

                    <div className="partner-header">
                      <h1 className="title">Você é um cooperado Unicred?</h1>
                    </div>

                    <div className="partner-fields">
                      <div className="partner-radios">
                        <div className="partner-radios-left">
                          <label className="partner-radios-field" htmlFor="yes">
                            <Radio
                              label="Sim, sou cooperado"
                              id="yes"
                              name="is_cooperated"
                              onChange={handlePartner}
                              value="yes"
                              checked={partner.is_cooperated === "yes"}
                            />
                          </label>
                        </div>

                        <div className="partner-radios-right">
                          <label className="partner-radios-field" htmlFor="no">
                            <Radio
                              label="Não"
                              id="no"
                              name="is_cooperated"
                              onChange={handlePartner}
                              value="no"
                              checked={partner.is_cooperated === "no"}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="partner-fields">
                      <div className="partner-actions">
                        <button className="partner-button" type="button" onClick={handleBack} disabled={submitting}>
                          Voltar
                        </button>
                        <Button type="submit" loading={submitting}>
                          Enviar
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : step === 3 ? (
              <div className="partner-success">
                <div className="partner-success-info">
                  <img src={success} alt="" />
                  <h1 className="title">Pronto!</h1>
                  <p>
                    Seus dados foram salvos e serão analisados.<br />
                    Em breve entraremos em contato.
                  </p>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </Page>
  );
}
