import { useState, useEffect, useCallback } from 'react';
import Page from 'components/Page';
import AdmPage from 'components/AdmPage';
import Modal from 'components/Modal';
import ConfirmationModal from 'components/ConfirmationModal';
import Button from 'components/Button';
import ButtonAction from 'components/ButtonAction';
import Note from 'components/Note';
import Loading, { Center } from 'components/Loading';
import Form from './Form';

import api from 'services/api';
import './vouchers.css';

export default function Vouchers() {
  const filters = [
    { value: 'physical_store', label: 'Voucher Lojas Físicas' },
    { value: 'online_store', label: 'Voucher Lojas Online' }
  ];
  const defaultFilter = filters[0].value;
  const [filter, setFilter] = useState(defaultFilter);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vouchers, setVouchers] = useState([]);

  const fetchVouchers = useCallback(() => {
    setLoading(true);

    api.get(`/v1/adm/vouchers?${filter}=true`)
      .then(({ data: vouchers }) => {
        setVouchers(vouchers);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || 'Não foi possivel buscar os vouchers!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    fetchVouchers();
  }, [fetchVouchers]);

  function handleNewVoucher() {
    handleModal();
  }

  function handleEditVoucher(voucher) {
    setSelectedVoucher(voucher);
    handleModal();
  }

  function handleDeleteVoucher(voucher) {
    setSelectedVoucher(voucher);
    setConfirmationModal(true);
  }

  function deleteVoucher(guid) {
    return api.delete(`/v1/adm/vouchers/${guid}`)
  }

  function handleFilter(value) {
    if (value === filter) return;
    setFilter(value);
  }

  function handleModal() {
    setModalIsOpen(!modalIsOpen);
  }

  function formatAddreses(addresses) {
    const titles = addresses.map(({ title }) => title).join(', ');
    return titles.length >= 48 ? `${titles.substr(0, 48)}...` : titles;
  }

  function formatStatus(status) {
    const statuses = {
      approved: 'Aprovado',
      awaiting_analysis: 'Análise'
    }
    return statuses[status] || '';
  }

  return (
    <Page>
      <AdmPage>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleModal}
        >
          <Form
            selectedVoucher={selectedVoucher}
            setSelectedVoucher={setSelectedVoucher}
            fetchVouchers={fetchVouchers}
            onRequestClose={handleModal}
          />
        </Modal>

        <ConfirmationModal
          title="Excluir voucher"
          description={`Tem certeza que deseja excluir o voucher "${selectedVoucher?.name}"?`}
          modalIsOpen={confirmationModal}
          setModalIsOpen={setConfirmationModal}
          submit={() => deleteVoucher(selectedVoucher?.guid)}
          onSuccess={fetchVouchers}
          onRequestClose={() => setSelectedVoucher(null)}
        />

        <div className="vouchers-header">
          <div className="vouchers-header-info">
            <h1 className="title">Vouchers</h1>
            <p>Veja seus vouchers já cadastrados abaixo:</p>
          </div>

          <div className="vouchers-header-actions">
            <Button onClick={handleNewVoucher} outline>Novo Voucher</Button>
          </div>
        </div>

        <ul className="tab-filter">
          {filters.map(({ value, label }) => (
            <li
              key={value}
              className={value === filter ? 'active' : ''}
              onClick={() => handleFilter(value)}
            >
              {label}
            </li>
          ))}
        </ul>

        <div className="vouchers-body">
          {loading ? (
            <Center>
              <Loading />
            </Center>
          ) : error ? (
            <Note>{error}</Note>
          ) : vouchers.length === 0 ? (
            <Note>Nenhum voucher cadastrado ainda!</Note>
          ) : (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Título</th>
                    <th>Localidades</th>
                    <th>Categoria</th>
                    <th>Quantidade</th>
                    <th>Curtidas</th>
                    <th>Status</th>
                    <th>Opções</th>
                  </tr>
                </thead>

                <tbody>
                  {vouchers.map((voucher) => (
                    <tr key={voucher.guid}>
                      <td>{voucher.name}</td>
                      <td>{formatAddreses(voucher.addresses)}</td>
                      <td>{voucher.category?.name}</td>
                      <td>{voucher.quantity}</td>
                      <td>{voucher.total_likes}</td>
                      <td className={`table-status${voucher.status === 'approved' ? ' active' : ''}`}>
                        {formatStatus(voucher.status)}
                      </td>
                      <td className="table-actions">
                        <div className="table-actions-container">
                          <ButtonAction action="edit" title="Editar" onClick={() => handleEditVoucher(voucher)} />
                          <ButtonAction action="delete" title="Deletar" onClick={() => handleDeleteVoucher(voucher)} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </AdmPage>
    </Page>
  );
}
