import iconEdit from 'assets/images/icon-edit.svg';
import iconDelete from 'assets/images/icon-delete.svg';
import './button-action.css';

export default function ButtonAction({ action, className, ...props }) {
  const icons = {
    edit: iconEdit,
    delete: iconDelete
  };

  return (
    <button className={`button-action${className ? ` ${className}` : ''}`} {...props}>
      <img src={icons[action]} alt="" />
    </button>
  );
}
