import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from 'pages/Home';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import ChangePassword from 'pages/ChangePassword';
import Profile from 'pages/Profile';
import Addresses from 'pages/Addresses';
import Vouchers from 'pages/Vouchers';
import Partner from 'pages/Partner';
import PartnerList from 'pages/PartnerList';
import CancelPartnership from 'pages/CancelPartnership';

import * as MeActions from 'store/modules/me/actions';
import api from 'services/api';
import { getToken, removeToken } from 'services/auth';

function Routes() {
  const token = getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      api.get('/v1/adm/me')
        .then(({ data: me }) => {
          dispatch(MeActions.me(me));
        })
        .catch(() => {
          removeToken();
        });
    }
  }, [token, dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/redefinir-senha" component={ResetPassword} />
        <Route path="/alterar-senha" component={ChangePassword} />
        <Route path="/seja-um-parceiro" component={Partner} />
        <Route path="/parceiros" component={PartnerList} />

        <PrivateRoute path="/meus-dados">
          <Profile />
        </PrivateRoute>

        <PrivateRoute path="/cancelar-parceria">
          <CancelPartnership />
        </PrivateRoute>

        <PrivateRoute path="/enderecos">
          <Addresses />
        </PrivateRoute>

        <PrivateRoute path="/vouchers">
          <Vouchers />
        </PrivateRoute>

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function PrivateRoute({ children, ...rest }) {
  const token = getToken();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: location
                }
              }}
            />
          )
      }
    />
  );
}

export default Routes;
