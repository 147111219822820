import { NavLink } from 'react-router-dom';

import files from 'assets/images/files.svg';
import marker from 'assets/images/marker.svg';
import voucher from 'assets/images/voucher.svg';
import './nav.css';

export default function Nav() {
  return (
    <ul className="adm-nav">
      <li>
        <NavLink activeClassName="active-menu" to="/meus-dados">
          <i>
            <img src={files} alt="Meu dados" />
          </i>
          <span>Meu dados</span>
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="active-menu" to="/enderecos">
          <i>
            <img src={marker} alt="Endereços" />
          </i>
          <span>Endereços</span>
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="active-menu" to="/vouchers">
          <i>
            <img src={voucher} alt="Vouchers" />
          </i>
          <span>Vouchers</span>
        </NavLink>
      </li>
    </ul>
  );
}
