import iconUpload from 'assets/images/upload.svg';
import iconUpload2 from 'assets/images/upload-2.svg';
import './upload.css';

export default function Upload({ name, value, className, image, onChange, type, size, info, height = 150 }) {
  return (
    <label
      htmlFor={name}
      className={`upload${className ? ` ${className}` : ''}${info ? ' with-info' : ''}`}
      style={{ '--height': `${height}px` }}
    >
      {image ? (
        <img src={URL.createObjectURL(image)} alt="" />
      ) : value ? (
        <img src={value} alt="" />
      ) : info ? (
        <div className="upload-info">
          <div className="upload-info-icon">
            <img src={iconUpload2} alt="" />
          </div>
          <div className="upload-info-text">
            {info.title && <span>{info.title}</span>}
            {info.type && <p>{info.type}</p>}
            {info.size && <p>{info.size}</p>}
          </div>
        </div>
      ) : (
        <div className="upload-icon">
          <div>
            <img src={iconUpload} alt="Upload" />
            {type && <p>{type}</p>}
            {size && <p>{size}</p>}
          </div>
        </div>
      )}

      <input
        id={name}
        name={name}
        type="file"
        value=""
        onChange={onChange}
        accept="image/jpg"
      />
    </label>
  );
}
