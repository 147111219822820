import { useRef } from 'react';
import './textarea.css';

export default function Textarea({ label, value, maxLength, ...props }) {
  const textInput = useRef(null);

  function handleClick() {
    textInput.current.focus();
  }

  return (
    <div className="textarea">
      {label && <label onClick={handleClick}>{label}</label>}
      <textarea
        value={value}
        maxLength={maxLength}
        ref={textInput}
        {...props}
      />
      {maxLength && (
        <span className="textarea-max-length" onClick={handleClick}>
          {(value || '').length}/{maxLength}
        </span>
      )}
    </div>
  );
}
