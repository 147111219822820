import { useState } from 'react';
import Modal from 'components/Modal'
import Text from './Text';

export default function Terms() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleModal() {
    setModalIsOpen(!modalIsOpen);
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModal}
      >
        <Text handleModal={handleModal} />
      </Modal>

      Li e aceito os&nbsp;<button type="button" onClick={handleModal}>termos de adesão</button>.
    </>
  );
}
