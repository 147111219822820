const options = {
    duration: 5000,
    style: {
        border: '1px solid  var(--color-primary)',
        padding: '16px',
        color: 'var( --color-secundary)',
    },
    success: {
        theme: {
            primary: 'var( --color-primary)',
            secondary: 'white',
        },
        iconTheme: {
            primary: 'var(--color-primary)',
            secondary: 'var( --color-secundary)',
        },
    },
    error: {
        theme: {
            primary: 'var( --color-primary)',
            secondary: 'white',
        },
        iconTheme: {
            primary: 'red',
            secondary: 'var( --color-secundary)',
        },
        promisse:{
            theme: {
                primary: 'var( --color-primary)',
                secondary: 'white',
            },
            iconTheme: {
                primary: 'var(--color-primary)',
                secondary: 'var( --color-secundary)',
            },
        }
        
    }
}
export default options;